<template>
  <admin-base ref="base">
    <div slot="title">
      {{ !this.$route.params.id ? 'Create' : 'Update' }} order {{ data.code }}
    </div>
    <b-form @submit.prevent @submit="saveData">
      <b-col class="card" md="12">
        <div class="card-body">
          <b-row>
            <b-col md="4">
              <b-form-group label="Code">
                <b-form-input v-model="data.code" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Status">
                <b-form-select v-model="data.status" :options="orderStatus" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Loja">
                <b-form-select v-model="data.store_id" :options="stores" />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Nome do comprador">
                <b-form-input v-model="data.eaterName" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Endereço do comprador">
                <b-form-input v-model="data.eaterAddress" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Telefone do comprador">
                <b-form-input v-model="data.eaterPhone" />
              </b-form-group>
            </b-col>

            <b-col md="12" class="mt-2">
              <h3>PickUp</h3>
            </b-col>
            <b-col md="6">
              <b-form-group label="Hora estimada para retirada">
                <b-form-input v-model="data.estimated_ready_for_pickup_at" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="retirada às">
                <b-form-input v-model="data.pickup_at" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="latitude">
                <b-form-input v-model="data.pickup_lat" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="longitude">
                <b-form-input v-model="data.pickup_lon" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="cidade">
                <b-form-input v-model="data.pickup_city" />
              </b-form-group>
            </b-col>

            <b-col md="12" class="mt-2">
              <h3>Delivery</h3>
            </b-col>
            <b-col md="6">
              <b-form-group label="Driver">
                <b-form-select v-model="data.driver_id" :options="drivers" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="entregue às">
                <b-form-input v-model="data.delivered_at" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="latitude">
                <b-form-input v-model="data.delivered_lat" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="longitude">
                <b-form-input v-model="data.delivered_lon" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="cidade">
                <b-form-input v-model="data.delivered_city" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Valor Entrega">
                <money v-model="data.deliveryCost" v-bind="money" class="form-control" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Custo Entrega">
                <money v-model="data.driverAmount" v-bind="money" class="form-control" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <!-- submit and reset -->
      <b-col class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          type="submit"
        >
          Save
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          @click="goBack"
        >
          Cancel
        </b-button>
        <b-button
          v-if="this.$route.params.orderid"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="danger"
          class="ml-1"
          @click="onDelete"
        >
          Delete
        </b-button>
      </b-col>
    </b-form>
  </admin-base>
</template>

<script>
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import * as bootstrapVue from 'bootstrap-vue'
import { Money } from 'v-money'
import useJwt from '@/auth/jwt/useJwt'
import AdminBase from '@/views/admin/AdminBase.vue'

export default {
  components: {
    AdminBase,
    BRow: bootstrapVue.BRow,
    BCol: bootstrapVue.BCol,
    BFormGroup: bootstrapVue.BFormGroup,
    BFormInput: bootstrapVue.BFormInput,
    BFormSelect: bootstrapVue.BFormSelect,
    BForm: bootstrapVue.BForm,
    BButton: bootstrapVue.BButton,
    Money,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
      orderStatus: {},
      drivers: {},
      stores: {},
      money: {
        decimal: '.',
        thousands: ',',
        suffix: ' €',
        precision: 2,
        masked: false,
      },
    }
  },
  mounted() {
    this.loadData()
  },
  created() {
  },
  methods: {
    loadData() {
      this.loadOrderStatus()
      this.loadDrivers()
      this.loadStores()
      if (!this.$route.params.id) {
        this.data = { enabled: true, approved: true }
      } else {
        this.$refs.base.showLoading()
        axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/stores/${this.$route.params.id}/orders/${this.$route.params.orderid}/show`)
        .then(response => {
          this.data = response.data.order
        })
        .catch(error => {
          this.$refs.base.checkError(error)
          this.$refs.base.showToastError(error.response.data.error)
          this.errored = true
        })
        .finally(() => {
          this.$refs.base.hideLoading()
        })
      }
    },
    loadOrderStatus() {
      const self = this
      this.$refs.base.showLoading()
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/stores/orders/status/list-select`, useJwt.getHeader())
      .then(response => {
        self.orderStatus = response.data.ordersstatus.map(u => ({ value: u.code, text: u.name })) ?? []
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(error.response.data.error)
        this.errored = true
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    loadDrivers() {
      const self = this
      this.$refs.base.showLoading()
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/drivers/list-select`, useJwt.getHeader())
      .then(response => {
        self.drivers = response.data.drivers.map(u => ({ value: u.id, text: u.name })) ?? []
        self.drivers.unshift({ value: null, text: '-' })
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(error.response.data.error)
        this.errored = true
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    loadStores() {
      const self = this
      this.$refs.base.showLoading()
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/stores/list-select`, useJwt.getHeader())
      .then(response => {
        self.stores = response.data.stores.map(u => ({ value: u.id, text: u.name })) ?? []
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(error.response.data.error)
        this.errored = true
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    saveData() {
      this.errored = false
      this.$refs.base.showLoading()
      if (!this.$route.params.id) {
        axios.post(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/stores/${this.$route.params.storeid}/orders/create`, this.data)
        .then(() => {
          this.$refs.base.showToast('Pedido criado com sucesso')
        })
        .catch(error => {
          this.$refs.base.checkError(error)
          this.$refs.base.showToastError(error.response.data.error)
          this.errored = true
        })
        .finally(() => {
          if (!this.errored) this.goBack()
          this.$refs.base.hideLoading()
        })
      } else {
        axios.put(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/stores/${this.$route.params.storeid}/orders/${this.$route.params.id}/update`, this.data)
        .then(() => {
          this.$refs.base.showToast('Pedido atualizado com sucesso')
        })
        .catch(error => {
          this.$refs.base.checkError(error)
          this.$refs.base.showToastError(error.response.data.error)
          this.errored = true
        })
        .finally(() => {
          if (!this.errored) this.goBack()
          this.$refs.base.hideLoading()
        })
      }
    },
    onDelete() {
      this.errored = false
      if (window.confirm('Deseja realmente remover o pedido?')) {
        axios.delete(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/stores/${this.$route.params.storeid}/orders/${this.$route.params.id}`)
        .then(() => {
          this.$refs.base.showToast('Pedido deletado com sucesso')
        })
        .catch(error => {
          this.$refs.base.checkError(error)
          this.$refs.base.showToastError(error.response.data.error)
          this.errored = true
        })
        .finally(() => {
          if (!this.errored) this.goBack()
          this.$refs.base.hideLoading()
        })
      }
    },
    goBack() {
      this.$refs.base.goBack()
    },
  },
}
</script>
